<template>
    <div class="page-admin-carriers table-page__thead--sticky">
        <div class="pb-3 mb-5 leading-tight border-gray-600 app-page-header flex flex__justify-between border-b">
            <div class="flex flex__justify-between w__100-p items-center app-page-header__container">
                <div class="flex flex__column">
                    <div class="flex header-tabs pt-6">
                        <div :class="{'active': activeTab === 'carriers'}" @click="setTab('carriers')">Carriers</div>
                        <div :class="{'active': activeTab === 'unmatched'}" @click="setTab('unmatched')">
                            Unmatched carriers
                            <span v-if="unmatchedCount !== null">({{unmatchedCount}})</span>
                        </div>
                    </div>
                </div>
                <button @click="$router.push({ name: 'admin.carriers.create' })" class="btn-primary block">
                    Add carrier
                </button>
            </div>
        </div>

        <unmatched-carriers v-if="activeTab === 'unmatched'" @total="unmatchedCount = $event"></unmatched-carriers>

        <div v-if="activeTab === 'carriers'">
            <div class="mt-6">
                <card class="bg-transparent" body-class="p-0 pl-0 pr-0 overflow-visible">
                    <v-table
                        :ref="tableRef"
                        checkable
                        :branding="false"
                        sortKey="name"
                        :sort-order="1"
                        :id="tableRef"
                        :key="tableRef"
                        class="admin-table"
                        :columns="columns()"
                        :endpoint="$apiUrl.carriers.base"
                        query-params
                        :page-size="50"
                        @row-dbclick="toDetail"
                    >
                        <template slot="cell" slot-scope="{row, col, index}">

                            <span v-if="col.key === 'logo_url'">
                                <img v-if="getLogoUrl(row)" class="h-8 uploading-image" :src="getLogoUrl(row)">
                                <span v-else>-</span>
                            </span>

                            <span v-else-if="col.key === 'number_of_services'">
                                {{ row.services.length }}
                            </span>

                            <span v-else-if="col.key === 'actions'" @click.stop>
                                <div class="flex flex__justify-end items-center ml-auto pl-4">
                                </div>
                            </span>

                            <span v-else>{{ row[col.key] ? row[col.key] : '-' }}</span>
                        </template>

                    </v-table>
                </card>
            </div>


            <!-- ACTION: Delete -->
            <modal :show="!!actionDeleteActive" @hide="turnOffActions()" size="sm">
                <h4 slot="header" v-if="actionDeleteActive" class="">Delete {{ actionDeleteActive.target[0].name }}</h4>

                <div slot="body" v-if="actionDeleteActive">
                    <div>
                        <p>You are about to delete {{ actionDeleteActive.target[0].name }}. Are you sure?</p>
                    </div>
                </div>

                <div v-if="actionDeleteActive" slot="footer" class="flex w-full">
                    <div class="ml-auto">
                        <button @click="turnOffActions()" class="btn-transparent">Cancel</button>
                        <button @click="deleteCarrier(actionDeleteActive.target[0])" class="btn-primary ml-4">Confirm</button>
                    </div>
                </div>
            </modal>

            <modal :loading="true" :show="loading"></modal>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import ModalField from "~/components/ModalField";
import UnmatchedCarriers from "./UnmatchedCarriers";
import AppTable from '~/components/vtable/AppTable'

export default {

    name: 'CarrierIndex',

    components: {
        UnmatchedCarriers,
        ModalField,
        AppTable
    },

    metaInfo () {
        return { title: 'Carriers' }
    },

    data: () => ({
        loading: false,
        activeTab: 'carriers',
        unmatchedCount: null,
        actionDeleteActive: null,
    }),

    computed: {
        tableRef() {
            return 'carriers';
        },
    },

    created() {
        this.setup();
    },

    methods: {
        toDetail(row) {
            window.open(`/admin/carriers/${row.data.id}`, '_blank');
        },

        runAction(actionName, target = false) {
            const targets = target ? [target] : this.toggledRowObjects;
            this.turnOffActions();

            if (targets.length === 0) {
                this.$snotify.warning('Please select at least one carrier.');
                return false;
            }

            if (actionName === 'edit') {
                this.$router.push({ name: 'admin.carriers.edit', params: { id: targets[0].id } })
            }

            if (actionName === 'delete') {
                this.actionDeleteActive = {
                    target: targets,
                };
            }
        },

        turnOffActions() {
            this.actionDeleteActive = null;
        },

        setTab(tab) {
            this.activeTab = tab;
        },

        deleteCarrier(carrier) {
            axios.delete(`${this.$apiUrl.carriers.base}/${carrier.id}`).then((response) => {
                this.refreshRows();
                this.turnOffActions();
            });
        },

        getLogoUrl(carrier) {
            if (carrier.media) {
                return carrier.media.url
            }
            return null;
        },

        setup() {
            this.fetchUnmatched();
        },

        async fetchUnmatched() {
            const {data} = await axios.get(this.$apiUrl.carrier.unmatched)
            this.unmatchedCount = data.meta.total;
        },

        refreshRows() {
            this.$refs[this.tableRef].refreshRows();
        },

        columns() {
            return [
                {
                    title: "Name",
                    key: "name",
                    toggled: true,
                    sortable: true,
                    sortable_key: 'name',
                    width: 'w-80'
                },
                // {
                //     title: "Tariff code",
                //     key: "code",
                //     toggled: true,
                //     sortable: true,
                //     sortable_key: 'code',
                //     width: 'w-32'
                // },
                // {
                //     title: "Consignor code",
                //     key: "consignor_code",
                //     toggled: true,
                //     sortable: true,
                //     sortable_key: 'code',
                //     width: 'w-32'
                // },
                {
                    title: "Number of services",
                    key: "number_of_services",
                    toggled: true,
                    sortable: false,
                    width: 'w-40'
                },
                {
                    title: "Logotype",
                    key: "logo_url",
                    toggled: true,
                    sortable: false,
                    width: 'w-24'
                },
                {
                    title: "",
                    key: "actions",
                    toggled: true,
                    sortable: false,
                    overflow: true,
                    width: 'w-24',
                    tdClass: 'text-right',
                },
            ];
        },
    }
}
</script>

<style scoped>
.uploading-image {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
} 
</style>

<style lang="scss">
.page-admin-carriers {
    .table-wrapper {
        max-height: calc(100vh - (234px));
        overflow: auto
    }

    .app-table__tr {
        height: 42.97px !important;
    }

    .app-table__th:last-child {
        text-align: right;
    }
}

.carriers-popover-content {
    left: 12px !important;
    z-index: 12;
}
</style>

<style lang="scss" scoped>
.header-tabs {
    @apply mt-auto flex;

    div, a {
        @apply inline-block mr-8 text-gray-500 pb-3 -mb-3 cursor-pointer;

        &.active {
            @apply border-b-2 border-gray-900 text-gray-900;
        }

        &:hover {
            @apply text-gray-600;

            text-decoration: none;
        }
    }

    div:last-child,a:last-child {
        @apply mr-0
    }
}
</style>