export default {

    data: () => ({
        id: null,
    }),

    computed: {
        vTable() {
            return this.$refs[this.id];
        },

        refs() {
            return this.$refs;
        },

        toggledRows() {
            return this.vTable.checkedRows();
        },

        total() {
            return this.vTable.total;
        },

        tableRows() {
            return this.vTable.getRows();
        },

        toggledRowObjects() {
            return this.toggledRows.map((id) => {
                return this.tableRows.find((row) => {
                    return row.id === id;
                })
            });
        },
    },

    methods: {

        calculatedTotalCost(article) {
            const adjusted = this.surchargesTotal(article.calculated_surcharges)
            const calculatedPrice = article.cat_2;

            return (calculatedPrice + adjusted);
        },

        getCalculatedWeight(article) {

            if (! article.consignment) {
                return article.calculated_weight
            }

            const consignmentWeight = (article.consignment.dimensions_weight * 100)
            const consignmentChargeableWeight = (article.consignment.dimensions_weight_chargeable * 100)

            return consignmentChargeableWeight > consignmentWeight
                ? consignmentChargeableWeight
                : consignmentWeight;
        },

        surchargesTotal(surcharges) {
            let total = 0;
            surcharges.forEach(surcharge => {
                total += surcharge.amount;
            })
            return total;
        },

        refreshRows() {
            this.vTable.refreshRows();
        },

        statusColor(status) {

            if (status === 'paid' || status === 'invoiced') {
                return 'bg-gray-500'
            }

            if (status === 'processing') {
                return 'bg-yellow-500'
            }

            if (status === 'exception') {
                return 'bg-red-500'
            }

            if (status === 'approved' || status === 'approved_with_exceptions') {
                return 'bg-green-500'
            }

            if (status === 'claimed') {
                return 'bg-purple-500'
            }

            if (status === 'error') {
                return 'bg-blue-500'
            }
        },


        statusText(status) {

            if (status === 'approved_with_exceptions') {
                return 'Approved (E)'
            }

            return status.charAt(0).toUpperCase() + status.substring(1);
        }
    }
}
